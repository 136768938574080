import React from 'react';
import { get } from 'lodash-es';
import paths from 'path';

//https://qyk2w.csb.app/
import { hierarchize } from './util/hierarchical';
import { AdminRoles, UserRoles } from 'util/Constant';

const keyName = 'key';
const pathName = 'path';
const uniqueKeyName = 'uniqueKey';

function pathGenerator(node, parent) {
  const parentUniqueKey = get(parent, uniqueKeyName);
  const uniqueKey = parentUniqueKey
    ? parentUniqueKey + '.' + node[keyName]
    : node[keyName];

  const parentPath = get(parent, pathName, '');
  const path = get(node, pathName, paths.join(parentPath, node[keyName]));
  node[uniqueKeyName] = uniqueKey;
  node[pathName] = path;
}

const routeConfig = hierarchize(
  {
    key: 'home',
    name: 'Home',
    icon: 'Home',
    path: '/',
    component: React.lazy(() => import('./pages/Dashboard')),
    children: [
      {
        key: 'login',
        name: 'Login',
        isPublic: true,
        isHidden: true,
        component: React.lazy(() => import('./pages/Login/Login')),
      },
      {
        key: 'profile',
        name: 'Profile',
        isHidden: true
      },
      {
        key: 'productinfo',
        name: 'Product Management',
        icon: 'Product',
        children: [
          {
            key: 'prodmgmt',
            name: 'Product Management',
            icon: 'Product',
            component: React.lazy(() => import('./pages/ProductManagement')),
          },
          {
            key: 'manufacturer',
            name: 'Manufacturer',
            icon: 'Manufacturing',
            isHidden: true,
            roles: [AdminRoles.ROOT_ADMIN, AdminRoles.ADMIN, UserRoles.MANUFACTURER_READ, UserRoles.MANUFACTURER_WRITE, UserRoles.MANUFACTURER_DELETE],
            component: React.lazy(() => import('./pages/Manufacturer/Manufacturer')),
          },
          {
            key: 'manufacturerForm/:manufacturerID',
            name: 'ManufacturerForm',
            icon: 'Manufacturing',
            isHidden: true,
            roles: [AdminRoles.ROOT_ADMIN, AdminRoles.ADMIN, UserRoles.MANUFACTURER_WRITE, UserRoles.MANUFACTURER_DELETE],
            component: React.lazy(() => import('./pages/Manufacturer/ManufacturerForm')),
          },
          {
            key: 'brand',
            name: 'Brand',
            icon: 'VerifiedBrand',
            isHidden: true,
            roles: [AdminRoles.ROOT_ADMIN, AdminRoles.ADMIN, UserRoles.BRAND_READ, UserRoles.BRAND_WRITE, UserRoles.BRAND_DELETE],
            component: React.lazy(() => import('./pages/Manufacturer/Brand/Brand')),
          },
          {
            key: 'product',
            name: 'Product',
            icon: 'ProductList',
            isHidden: true,
            roles: [AdminRoles.ROOT_ADMIN, AdminRoles.ADMIN, UserRoles.PRODUCT_MODEL_READ, UserRoles.PRODUCT_MODEL_WRITE, UserRoles.PRODUCT_MODEL_DELETE, UserRoles.PRODUCT_MODEL_PUBLISH],
            component: React.lazy(() => import('./pages/Product/ProductModel/Product')),
          },
          {
            key: 'productForm/:productID',
            name: 'ProductForm',
            icon: 'ProductRelease',
            isHidden: true,
            roles: [AdminRoles.ROOT_ADMIN, AdminRoles.ADMIN, UserRoles.PRODUCT_MODEL_WRITE, UserRoles.PRODUCT_MODEL_DELETE, UserRoles.PRODUCT_MODEL_PUBLISH],
            component: React.lazy(() => import('./pages/Product/ProductModel/ProductForm')),
          },
          {
            key: 'productVariant',
            name: 'ProductVariant',
            icon: 'ProductVariant',
            isHidden: true,
            roles: [AdminRoles.ROOT_ADMIN, AdminRoles.ADMIN, UserRoles.PRODUCT_VARIANT_READ, UserRoles.PRODUCT_VARIANT_WRITE, UserRoles.PRODUCT_VARIANT_DELETE, UserRoles.PRODUCT_VARIANT_PUBLISH],
            component: React.lazy(() => import('./pages/Product/ProductVariant/ProductVariant')),
          },
          {
            key: 'productVariantForm/:productModelID/variant/:productVariantID',
            name: 'ProductVariantForm',
            icon: 'ProductVariant',
            isHidden: true,
            roles: [AdminRoles.ROOT_ADMIN, AdminRoles.ADMIN, UserRoles.PRODUCT_VARIANT_WRITE, UserRoles.PRODUCT_VARIANT_DELETE, UserRoles.PRODUCT_VARIANT_PUBLISH],
            component: React.lazy(() => import('./pages/Product/ProductVariant/ProductVariantForm')),
          },
          // {
          //   key: 'productVariantSpecificationForm/:productVariantID',
          //   name: 'ProductVariantSpecificationForm',
          //   icon:'ProductVariant',
          //   isHidden: true,
          //   roles: [AdminRoles.ROOT_ADMIN,AdminRoles.ADMIN,UserRoles.PRODUCT_VARIANT_READ,UserRoles.PRODUCT_VARIANT_WRITE,UserRoles.PRODUCT_VARIANT_DELETE,UserRoles.PRODUCT_VARIANT_PUBLISH],
          //   component: React.lazy(() => import('./pages/Product/ProductVariant/Specification/SpecificationsForm')),
          // },
          {
            key: 'productSpecificationForm/:productModelID/variant/:productVariantID',
            name: 'ProductSpecificationForm',
            icon: 'ProductModel',
            isHidden: true,
            roles: [AdminRoles.ROOT_ADMIN, AdminRoles.ADMIN, UserRoles.PRODUCT_MODEL_READ, UserRoles.PRODUCT_MODEL_WRITE, UserRoles.PRODUCT_MODEL_DELETE, UserRoles.PRODUCT_MODEL_PUBLISH],
            component: React.lazy(() => import('./pages/Product/Specification/SpecificationsForm')),
          },
          {
            key: 'pricingForm/:pricingID',
            name: 'PricingForm',
            icon: 'AllCurrency',
            isHidden: true,
            roles: [AdminRoles.ROOT_ADMIN, AdminRoles.ADMIN, UserRoles.PRICE_READ, UserRoles.PRICE_WRITE, UserRoles.PRICE_DELETE, UserRoles.PRICE_PUBLISH],
            component: React.lazy(() => import('./pages/Product/Pricing/PricingForm')),
          },
          {
            key: 'prodlistingmgmt',
            name: 'Product List Management',
            icon: 'PageListFilter',
            component: React.lazy(() => import('./pages/ProductListingManagement')),
          },
          {
            key: 'pricelist',
            name: 'Pricelist',
            icon: 'BullseyeTargetEdit',
            isHidden: true,
            roles: [AdminRoles.ROOT_ADMIN, AdminRoles.ADMIN, UserRoles.PRICE_READ, UserRoles.PRICE_WRITE, UserRoles.PRICE_DELETE, UserRoles.PRICE_PUBLISH],
            component: React.lazy(() => import('./pages/Product/Pricing/Pricelist/Pricelists')),
          },
          {
            key: 'pricelistForm/:pricingID',
            name: 'PricelistForm',
            icon: 'BullseyeTargetEdit',
            isHidden: true,
            roles: [AdminRoles.ROOT_ADMIN, AdminRoles.ADMIN, UserRoles.PRICE_READ, UserRoles.PRICE_WRITE, UserRoles.PRICE_DELETE, UserRoles.PRICE_PUBLISH],
            component: React.lazy(() => import('./pages/Product/Pricing/Pricelist/PricelistForm')),
          },
          {
            key: 'advancedPricingRules',
            name: 'AdvancedPricingRules',
            icon: 'FilterSettings',
            isHidden: true,
            roles: [AdminRoles.ROOT_ADMIN, AdminRoles.ADMIN, UserRoles.PRICE_READ, UserRoles.PRICE_WRITE, UserRoles.PRICE_DELETE, UserRoles.PRICE_PUBLISH],
            component: React.lazy(() => import('./pages/Product/Pricing/Rules/AdvancedPricingRules')),
          },
          {
            key: 'advancedPricingRulesForm/:pricingID',
            name: 'AdvancedPricingRulesForm',
            icon: 'AllCurrency',
            isHidden: true,
            roles: [AdminRoles.ROOT_ADMIN, AdminRoles.ADMIN, UserRoles.PRICE_READ, UserRoles.PRICE_WRITE, UserRoles.PRICE_DELETE, UserRoles.PRICE_PUBLISH],
            component: React.lazy(() => import('./pages/Product/Pricing/Rules/AdvancedPricingRulesForm')),
          },
          {
            key: 'productListing',
            name: 'ProductListing',
            icon: 'WaitlistConfirm',
            isHidden: true,
            roles: [AdminRoles.ROOT_ADMIN, AdminRoles.ADMIN, UserRoles.PRODUCT_LISTING_READ, UserRoles.PRODUCT_LISTING_WRITE, UserRoles.PRODUCT_LISTING_DELETE, UserRoles.PRODUCT_LISTING_PUBLISH],
            component: React.lazy(() => import('./pages/Product/Listing/ProductListing')),
          },
          {
            key: 'priceListPriority',
            name: 'PricelistPriority',
            icon: 'WaitlistConfirm',
            isHidden: true,
            roles: [AdminRoles.ROOT_ADMIN, AdminRoles.ADMIN, UserRoles.PRODUCT_LISTING_READ, UserRoles.PRODUCT_LISTING_WRITE, UserRoles.PRODUCT_LISTING_DELETE, UserRoles.PRODUCT_LISTING_PUBLISH],
            component: React.lazy(() => import('./pages/Product/Store/PricelistPriority')),
          },
          {
            key: 'PriceListPriorityForm/:storeID',
            name: 'PriceListPriorityForm',
            icon: 'ProductRelease',
            isHidden: true,
            roles: [AdminRoles.ROOT_ADMIN, AdminRoles.ADMIN, UserRoles.PRODUCT_ADDON_WRITE, UserRoles.PRODUCT_ADDON_DELETE, UserRoles.PRODUCT_ADDON_PUBLISH],
            component: React.lazy(() => import('./pages/Product/Store/PriceListPriorityForm')),
          },
          {
            key: 'CompetitorPrice',
            name: 'CompetitorPriceList',
            icon: 'ProductRelease',
            isHidden: true,
            roles: [AdminRoles.ROOT_ADMIN, AdminRoles.ADMIN, UserRoles.PRODUCT_LISTING_READ, UserRoles.PRODUCT_LISTING_WRITE, UserRoles.PRODUCT_LISTING_DELETE],
            component: React.lazy(() => import('./pages/CompetitorPrice/CompetitorPrice')),
          },
          {
            key: 'productAddOn',
            name: 'AddOn',
            icon: 'AddIn',
            isHidden: true,
            roles: [AdminRoles.ROOT_ADMIN, AdminRoles.ADMIN, UserRoles.PRODUCT_ADDON_READ, UserRoles.PRODUCT_ADDON_WRITE, UserRoles.PRODUCT_ADDON_DELETE, UserRoles.PRODUCT_ADDON_PUBLISH],
            component: React.lazy(() => import('./pages/Product/AddOn/ProductAddOn')),
          },
          {
            key: 'productAddOnForm/:productID',
            name: 'ProductAddOnForm',
            icon: 'ProductRelease',
            isHidden: true,
            roles: [AdminRoles.ROOT_ADMIN, AdminRoles.ADMIN, UserRoles.PRODUCT_ADDON_WRITE, UserRoles.PRODUCT_ADDON_DELETE, UserRoles.PRODUCT_ADDON_PUBLISH],
            component: React.lazy(() => import('./pages/Product/AddOn/ProductAddOnForm')),
          },
          {
            key: 'productPolicy',
            name: 'ProductPolicy',
            icon: 'AddIn',
            isHidden: true,
            roles: [AdminRoles.ROOT_ADMIN, AdminRoles.ADMIN, UserRoles.PRODUCT_ADDON_READ, UserRoles.PRODUCT_ADDON_WRITE, UserRoles.PRODUCT_ADDON_DELETE, UserRoles.PRODUCT_ADDON_PUBLISH],
            component: React.lazy(() => import('./pages/Product/Policy/ProductPolicy')),
          },
          {
            key: 'productPolicyForm/:policyID',
            name: 'ProductPolicyForm',
            icon: 'ProductRelease',
            isHidden: true,
            roles: [AdminRoles.ROOT_ADMIN, AdminRoles.ADMIN, UserRoles.PRODUCT_ADDON_WRITE, UserRoles.PRODUCT_ADDON_DELETE, UserRoles.PRODUCT_ADDON_PUBLISH],
            component: React.lazy(() => import('./pages/Product/Policy/ProductPolicyForm')),
          },
          {
            key: 'channelVariantShapeForm/:channelID/variant/:productVariantID',
            name: 'ChannelVarinatShapeForm',
            icon: 'ProductRelease',
            isHidden: true,
            roles: [AdminRoles.ROOT_ADMIN, AdminRoles.ADMIN, UserRoles.PRODUCT_VARIANT_WRITE, UserRoles.PRODUCT_VARIANT_DELETE, UserRoles.PRODUCT_VARIANT_PUBLISH],
            component: React.lazy(() => import('./pages/Product/ProductVariant/Channel/ChannelVariantShapeForm')),
          },
          {
            key: 'family',
            name: 'Family',
            icon: 'Family',
            isHidden: true,
            roles: [AdminRoles.ROOT_ADMIN, AdminRoles.ADMIN, UserRoles.FAMILY_READ, UserRoles.FAMILY_WRITE, UserRoles.FAMILY_DELETE],
            component: React.lazy(() => import('./pages/Family/Family')),
          },
          {
            key: 'familyForm/:familyID',
            name: 'FamilyForm',
            icon: 'ProductRelease',
            isHidden: true,
            roles: [AdminRoles.ROOT_ADMIN, AdminRoles.ADMIN, UserRoles.FAMILY_WRITE, UserRoles.FAMILY_DELETE],
            component: React.lazy(() => import('./pages/Family/FamilyForm')),
          },
          {
            key: 'variantAttributeShape',
            name: 'VariantAttribute Shape',
            icon: 'Shapes',
            isHidden: true,
            roles: [AdminRoles.ROOT_ADMIN, AdminRoles.ADMIN, UserRoles.ATTRIBUTE_VARIANT_SHAPE_READ, UserRoles.ATTRIBUTE_VARIANT_SHAPE_WRITE, UserRoles.ATTRIBUTE_VARIANT_SHAPE_DELETE],
            component: React.lazy(() => import('./pages/Product/VariantAttribute/VariantAttributeShape')),
          },
          {
            key: 'attributeShape',
            name: 'Specification Shape',
            icon: 'CheckListCheck',
            isHidden: true,
            roles: [AdminRoles.ROOT_ADMIN, AdminRoles.ADMIN, UserRoles.ATTRIBUTE_SHAPE_READ, UserRoles.ATTRIBUTE_SHAPE_WRITE, UserRoles.ATTRIBUTE_SHAPE_DELETE],
            component: React.lazy(() => import('./pages/Attribute/AttributeShapeList')),
          },
          {
            key: 'attributeShapeForm/:attributeShapeID',
            name: 'Specification Shape Form',
            isHidden: true,
            roles: [AdminRoles.ROOT_ADMIN, AdminRoles.ADMIN, UserRoles.ATTRIBUTE_SHAPE_WRITE, UserRoles.ATTRIBUTE_SHAPE_DELETE],
            component: React.lazy(() => import('./pages/Attribute/AttributeShapeForm')),
          },
          {
            key: 'attributeGroupCode',
            name: 'Attribute Group Code',
            icon: 'GroupList',
            isHidden: true,
            roles: [AdminRoles.ROOT_ADMIN, AdminRoles.ADMIN, UserRoles.ATTRIBUTE_SHAPE_READ, UserRoles.ATTRIBUTE_SHAPE_WRITE, UserRoles.ATTRIBUTE_SHAPE_DELETE],
            component: React.lazy(() => import('./pages/Attribute/AttributeGroupCodeList')),
          },
          {
            key: 'attributeCode',
            name: 'Attribute Code',
            icon: 'TaskGroup',
            isHidden: true,
            roles: [AdminRoles.ROOT_ADMIN, AdminRoles.ADMIN, UserRoles.ATTRIBUTE_SHAPE_READ, UserRoles.ATTRIBUTE_SHAPE_WRITE, UserRoles.ATTRIBUTE_SHAPE_DELETE],
            component: React.lazy(() => import('./pages/Attribute/AttributeCodeList')),
          },
          {
            key: 'attributeValueCode',
            name: 'Attribute Value Code',
            icon: 'TriangleSolid',
            isHidden: true,
            roles: [AdminRoles.ROOT_ADMIN, AdminRoles.ADMIN, UserRoles.ATTRIBUTE_SHAPE_READ, UserRoles.ATTRIBUTE_SHAPE_WRITE, UserRoles.ATTRIBUTE_SHAPE_DELETE],
            component: React.lazy(() => import('./pages/Attribute/AttributeValueCodeList')),
          },
          {
            key: 'typescencecode',
            name: 'typescence',
            icon: 'ProductList',
            isHidden: true,
            roles: [AdminRoles.ROOT_ADMIN, AdminRoles.ADMIN, UserRoles.PRODUCT_MODEL_READ, UserRoles.PRODUCT_MODEL_WRITE, UserRoles.PRODUCT_MODEL_DELETE, UserRoles.PRODUCT_MODEL_PUBLISH],
            component: React.lazy(() => import('./pages/TypeScence/TypeScence')),
          }
        ]
      },
      {
        key: 'category',
        name: 'Manage Catalogue',
        icon: 'ProductCatalog',
        children: [
          {
            key: 'manage',
            name: 'Catalogue',
            icon: 'ProductCatalog',
            isHidden: true,
            component: React.lazy(() => import('./pages/CatalogueManagement')),
          },
          {
            key: 'list',
            name: 'Catalogue',
            icon: 'ProductCatalog',
            isHidden: true,
            roles: [AdminRoles.ROOT_ADMIN, AdminRoles.ADMIN, UserRoles.CATALOGUE_READ, UserRoles.CATALOGUE_WRITE, UserRoles.CATALOGUE_DELETE, UserRoles.CATALOGUE_PUBLISH],
            component: React.lazy(() => import('./pages/Category')),
          },

        ]
      },
      {
        key: 'page',
        name: 'Manage Pages',
        children: [
          {
            key: 'manage',
            name: 'Content Management',
            icon: 'TextBox',
            component: React.lazy(() => import('./pages/ContentManagement')),
          },
          {
            key: 'navigation',
            name: 'Navigation',
            icon: 'PageLink',
            isHidden: true,
            roles: [AdminRoles.ROOT_ADMIN, AdminRoles.ADMIN, UserRoles.NAVIGATION_READ, UserRoles.NAVIGATION_WRITE, UserRoles.NAVIGATION_DELETE, UserRoles.NAVIGATION_PUBLISH],
            component: React.lazy(() => import('./pages/Navigation')),
          },
          // {
          //   key: 'NavigationLink',
          //   name: 'NavigationLink',
          //   icon:'PageLink',
          //   isHidden:true,
          //   roles: [AdminRoles.ROOT_ADMIN,AdminRoles.ADMIN,UserRoles.NAVIGATION_READ,UserRoles.NAVIGATION_WRITE,UserRoles.NAVIGATION_DELETE,UserRoles.NAVIGATION_PUBLISH],
          //   component: React.lazy(() => import('./pages/Breadcrumb')),
          // },
          {
            key: 'page',
            name: 'Page',
            icon: 'PageLink',
            isHidden: true,
            roles: [AdminRoles.ROOT_ADMIN, AdminRoles.ADMIN, UserRoles.PAGE_READ, UserRoles.PAGE_WRITE, UserRoles.PAGE_DELETE, UserRoles.PAGE_PUBLISH],
            component: React.lazy(() => import('./pages/Page/PageList')),
          },
          {
            key: 'pageForm/:pageID',
            name: 'PageForm',
            icon: 'PageLink',
            roles: [AdminRoles.ROOT_ADMIN, AdminRoles.ADMIN, UserRoles.PAGE_WRITE, UserRoles.PAGE_DELETE, UserRoles.PAGE_PUBLISH],
            isHidden: true,
            component: React.lazy(() => import('./pages/Page/PageForm')),
          },
          {
            key: 'pageproduct',
            name: 'Product',
            icon: 'PageLink',
            isHidden: true,
            roles: [AdminRoles.ROOT_ADMIN, AdminRoles.ADMIN, UserRoles.PRODUCTPAGE_READ, UserRoles.PRODUCTPAGE_WRITE, UserRoles.PRODUCTPAGE_DELETE, UserRoles.PRODUCTPAGE_PUBLISH],
            component: React.lazy(() => import('./pages/Page/Product/ProductPageElementList')),
          },
          {
            key: 'micropage',
            name: 'MicroPage',
            icon: 'PageLink',
            isHidden: true,
            roles: [AdminRoles.ROOT_ADMIN, AdminRoles.ADMIN, UserRoles.PRODUCTPAGE_READ, UserRoles.PRODUCTPAGE_WRITE, UserRoles.PRODUCTPAGE_DELETE, UserRoles.PRODUCTPAGE_PUBLISH],
            component: React.lazy(() => import('./pages/MicroPage/MicroPageList')),
          },
          {
            key: 'micropageForm/:pageID',
            name: 'MicroPageForm',
            icon: 'PageLink',
            roles: [AdminRoles.ROOT_ADMIN, AdminRoles.ADMIN, UserRoles.PRODUCTPAGE_WRITE, UserRoles.PRODUCTPAGE_DELETE, UserRoles.PRODUCTPAGE_PUBLISH],
            isHidden: true,
            component: React.lazy(() => import('./pages/MicroPage/MicroPageForm')),
          },
          {
            key: 'productPageElementForm/:productPageElementID',
            name: 'PageForm',
            icon: 'PageLink',
            roles: [AdminRoles.ROOT_ADMIN, AdminRoles.ADMIN, UserRoles.PRODUCTPAGE_WRITE, UserRoles.PRODUCTPAGE_DELETE, UserRoles.PRODUCTPAGE_PUBLISH],
            isHidden: true,
            component: React.lazy(() => import('./pages/Page/Product/ProductPageElementForm')),
          },
          {
            key: 'group',
            name: 'Group',
            icon: 'RowsGroup',
            isHidden: true,
            roles: [AdminRoles.ROOT_ADMIN, AdminRoles.ADMIN, UserRoles.GROUP_READ, UserRoles.GROUP_WRITE, UserRoles.GROUP_DELETE],
            component: React.lazy(() => import('./pages/Group/Product/GroupProduct')),
          },
          {
            key: 'groupForm/:groupID',
            name: 'GroupForm',
            icon: 'ProductRelease',
            isHidden: true,
            roles: [AdminRoles.ROOT_ADMIN, AdminRoles.ADMIN, UserRoles.GROUP_WRITE, UserRoles.GROUP_DELETE],
            component: React.lazy(() => import('./pages/Group/Product/GroupProductForm')),
          },
          {
            key: 'groupVideo',
            name: 'Group Video',
            icon: 'VideoSearch',
            isHidden: true,
            roles: [AdminRoles.ROOT_ADMIN, AdminRoles.ADMIN, UserRoles.GROUP_READ, UserRoles.GROUP_WRITE, UserRoles.GROUP_DELETE],
            component: React.lazy(() => import('./pages/Group/Video/GroupVideo')),
          },
          {
            key: 'groupVideoForm/:groupID',
            name: 'GroupVideoForm',
            icon: 'Video',
            isHidden: true,
            roles: [AdminRoles.ROOT_ADMIN, AdminRoles.ADMIN, UserRoles.GROUP_WRITE, UserRoles.GROUP_DELETE],
            component: React.lazy(() => import('./pages/Group/Video/GroupVideoForm')),
          },
          {
            key: 'groupCategory',
            name: 'Group Category',
            icon: 'VideoSearch',
            isHidden: true,
            roles: [AdminRoles.ROOT_ADMIN, AdminRoles.ADMIN, UserRoles.GROUP_READ, UserRoles.GROUP_WRITE, UserRoles.GROUP_DELETE],
            component: React.lazy(() => import('./pages/Group/Category/GroupCategory')),
          },
          {
            key: 'groupCategoryForm/:groupID',
            name: 'GroupCategoryForm',
            icon: 'Video',
            isHidden: true,
            roles: [AdminRoles.ROOT_ADMIN, AdminRoles.ADMIN, UserRoles.GROUP_WRITE, UserRoles.GROUP_DELETE],
            component: React.lazy(() => import('./pages/Group/Category/GroupCategoryForm')),
          },
          {
            key: 'groupBrand',
            name: 'Group Brand',
            icon: 'VideoSearch',
            isHidden: true,
            roles: [AdminRoles.ROOT_ADMIN, AdminRoles.ADMIN, UserRoles.GROUP_READ, UserRoles.GROUP_WRITE, UserRoles.GROUP_DELETE],
            component: React.lazy(() => import('./pages/Group/Brand/GroupBrand')),
          },
          {
            key: 'groupBrandForm/:groupID',
            name: 'GroupBrandForm',
            icon: 'Video',
            isHidden: true,
            roles: [AdminRoles.ROOT_ADMIN, AdminRoles.ADMIN, UserRoles.GROUP_WRITE, UserRoles.GROUP_DELETE],
            component: React.lazy(() => import('./pages/Group/Brand/GroupBrandForm')),
          },

          {
            key: 'groupImage',
            name: 'Group Image',
            icon: 'ImageSearch',
            isHidden: true,
            roles: [AdminRoles.ROOT_ADMIN, AdminRoles.ADMIN, UserRoles.GROUP_READ, UserRoles.GROUP_WRITE, UserRoles.GROUP_DELETE],
            component: React.lazy(() => import('./pages/Group/Image/GroupImage')),
          },
          {
            key: 'groupImageForm/:groupID',
            name: 'GroupImageForm',
            icon: 'Image',
            isHidden: true,
            roles: [AdminRoles.ROOT_ADMIN, AdminRoles.ADMIN, UserRoles.GROUP_WRITE, UserRoles.GROUP_DELETE],
            component: React.lazy(() => import('./pages/Group/Image/GroupImageForm')),
          },
        ]
      },

      {
        key: 'asset',
        name: 'Manage Assets',
        icon: 'AssetLibrary',
        children: [
          {
            key: 'manage',
            name: 'Assets',
            icon: 'AssetLibrary',
            isHidden: true,
            component: React.lazy(() => import('./pages/AssetManagement')),
          },
          {
            key: 'assets',
            name: 'Assets',
            icon: 'AssetLibrary',
            isHidden: true,
            roles: [AdminRoles.ROOT_ADMIN, AdminRoles.ADMIN, UserRoles.ASSET_READ, UserRoles.ASSET_WRITE, UserRoles.ASSET_DELETE],
            component: React.lazy(() => import('./components/FileManager/FileExplorer')),
          },
          {
            key: 'shapes',
            name: 'Shapes',
            icon: 'Shapes',
            isHidden: true,
            roles: [AdminRoles.ROOT_ADMIN, AdminRoles.ADMIN, UserRoles.IMAGESHAPE_READ, UserRoles.IMAGESHAPE_WRITE, UserRoles.IMAGESHAPE_DELETE],
            component: React.lazy(() => import('./pages/Image/ImageShape')),
          },
        ]
      },
      {
        key: 'filters',
        name: 'Tag Management',
        icon: 'FilterSettings',
        children: [
          {
            key: 'manage',
            name: 'Facets & Tags',
            icon: 'WebAppBuilderFragment',
            isHidden: true,
            component: React.lazy(() => import('./pages/TagManagement')),
          },
          {
            key: 'facets',
            name: 'Facets',
            icon: 'WebAppBuilderFragment',
            isHidden: true,
            roles: [AdminRoles.ROOT_ADMIN, AdminRoles.ADMIN, UserRoles.FACETS_READ, UserRoles.FACETS_WRITE, UserRoles.FACETS_DELETE],
            component: React.lazy(() => import('./pages/Facet/Facet')),
          },
          {
            key: 'facetForm/:facetID',
            name: 'Facet Form',
            isHidden: true,
            roles: [AdminRoles.ROOT_ADMIN, AdminRoles.ADMIN, UserRoles.FACETS_WRITE, UserRoles.FACETS_DELETE],
            component: React.lazy(() => import('./pages/Facet/FacetForm')),
          },
          {
            key: 'tag',
            name: 'Tag',
            icon: 'Tag',
            isHidden: true,
            roles: [AdminRoles.ROOT_ADMIN, AdminRoles.ADMIN, UserRoles.TAGS_READ, UserRoles.TAGS_WRITE, UserRoles.TAGS_DELETE],
            component: React.lazy(() => import('./pages/Tag/Tag')),
          }
        ]
      },
      {
        key: 'exchangemodule',
        name: 'Exchange Management',
        icon: 'DataManagementSettings',
        children: [
          {
            key: 'exchangemodule',
            name: 'Exchange Management',
            icon: 'DataManagementSettings',
            // roles: [AdminRoles.ROOT_ADMIN, AdminRoles.ADMIN],
            component: React.lazy(() => import('./pages/ExchangeModule')),
          },
          {
            key: 'variant',
            name: 'Variant',
            icon: 'ProductList',
            isHidden: true,
            // roles: [AdminRoles.ROOT_ADMIN, AdminRoles.ADMIN, UserRoles.PRODUCT_MODEL_READ, UserRoles.PRODUCT_MODEL_WRITE, UserRoles.PRODUCT_MODEL_DELETE, UserRoles.PRODUCT_MODEL_PUBLISH],
            component: React.lazy(() => import('./pages/Exchange/ExchangeVariant')),
          },
          {
            key: 'exchangevariantform/:exchangeVariantID',
            // :productModelID/variant/:productVariantID',
            name: 'Exchange Variant Form',
            icon: 'ProductVariant',
            isHidden: true,
            // roles: [AdminRoles.ROOT_ADMIN, AdminRoles.ADMIN, UserRoles.PRODUCT_VARIANT_WRITE, UserRoles.PRODUCT_VARIANT_DELETE, UserRoles.PRODUCT_VARIANT_PUBLISH],
            component: React.lazy(() => import('./pages/Exchange/ExchangeVariantForm')),
          },
          {
            key: 'questionnaries',
            name: 'Questionnaires',
            icon: 'MapLayers',
            isHidden: true,
            // roles: [AdminRoles.ROOT_ADMIN, AdminRoles.ADMIN],
            component: React.lazy(() => import('./pages/Exchange/Questionnaries/Questionnaries')),
          },
          {
            key: 'questionnarieform/:QuestionnarieId',
            name: 'Questionnariesupdate',
            icon: 'MapLayers',
            isHidden: true,
            // roles: [AdminRoles.ROOT_ADMIN, AdminRoles.ADMIN],
            component: React.lazy(() => import('./pages/Exchange/Questionnaries/Questionnarieform')),
          },
          {
            key: 'QuestionCat',
            name: 'Questionnariescategory',
            icon: 'MapLayers',
            isHidden: true,
            // roles: [AdminRoles.ROOT_ADMIN, AdminRoles.ADMIN],
            component: React.lazy(() => import('./pages/Exchange/QuestionCategory')),
          },
          {
            key: 'CategoryForm/:QuestionCatID',
            name: 'CategoryForm',
            icon: 'MapLayers',
            isHidden: true,
            // roles: [AdminRoles.ROOT_ADMIN, AdminRoles.ADMIN],
            component: React.lazy(() => import('./pages/Exchange/QuestionCategory/CategoryForm')),
          },
          {
            key: 'QuestionCat',
            name: 'Questionnariescategory',
            icon: 'MapLayers',
            isHidden: true,
            // roles: [AdminRoles.ROOT_ADMIN, AdminRoles.ADMIN],
            component: React.lazy(() => import('./pages/Exchange/QuestionCategory')),
          },
          {
            key: 'Questions',
            name: 'Questionnariescategory',
            icon: 'MapLayers',
            isHidden: true,
            // roles: [AdminRoles.ROOT_ADMIN, AdminRoles.ADMIN],
            component: React.lazy(() => import('./pages/Exchange/Questions')),
          },
          {
            key: 'Questionsform/:QuestionID',
            name: 'Questionnariescategory',
            icon: 'MapLayers',
            isHidden: true,
            // roles: [AdminRoles.ROOT_ADMIN, AdminRoles.ADMIN],
            component: React.lazy(() => import('./pages/Exchange/Questions/QuestionForm')),
          },
          {
            key: 'vendordetails',
            name: 'Vendor Pricing',
            icon: 'MapLayers',
            isHidden: true,
            // roles: [AdminRoles.ROOT_ADMIN, AdminRoles.ADMIN],
            component: React.lazy(() => import('./pages/Exchange/Vendor/VendorForm')),
          },
          // {
          //   key: 'channelShapeForm/:channelID',
          //   name: 'ChannelShapeForm',
          //   icon: 'MapLayers',
          //   isHidden:true,
          //   roles: [AdminRoles.ROOT_ADMIN,AdminRoles.ADMIN],
          //   component: React.lazy(() => import('./pages/Channel/Shape/ChannelShapeForm')),
          // },
          // {
          //   key: 'settings',
          //   name: 'Settings',
          //   icon: 'Settings',
          //   isHidden:true,
          //   children: [
          //     {
          //       key: 'settings',
          //       name: 'Settings',
          //       roles: [AdminRoles.ROOT_ADMIN,AdminRoles.ADMIN],
          //       component: React.lazy(() => import('./pages/Settings/Settings')),
          //     },
          //     {
          //       key: 'country',
          //       name: 'Country',
          //       roles: [AdminRoles.ROOT_ADMIN,AdminRoles.ADMIN],
          //       component: React.lazy(() => import('./pages/Country')),
          //     },
          //     {
          //       key: 'locale',
          //       name: 'Locale',
          //       roles: [AdminRoles.ROOT_ADMIN,AdminRoles.ADMIN],
          //       component: React.lazy(() => import('./pages/Locale')),
          //     },
          //     {
          //       key: 'currency',
          //       name: 'Currency',
          //       roles: [AdminRoles.ROOT_ADMIN,AdminRoles.ADMIN],
          //       component: React.lazy(() => import('./pages/Currency')),
          //     },
          //     {
          //       key: 'user',
          //       name: 'User',
          //       icon: 'People',
          //       roles: [AdminRoles.ROOT_ADMIN,AdminRoles.ADMIN],
          //       component: React.lazy(() => import('./pages/User/User')),
          //     },
          //     {
          //       key: 'userRole',
          //       name: 'Roles',
          //       icon: 'SecurityGroup',
          //       roles: [AdminRoles.ROOT_ADMIN,AdminRoles.ADMIN],
          //       component: React.lazy(() => import('./pages/User/UserRole')),
          //     },
          //     {
          //       key: 'measure',
          //       name: 'Measure',
          //       icon: 'Weights',
          //       roles: [AdminRoles.ROOT_ADMIN,AdminRoles.ADMIN],
          //       component: React.lazy(() => import('./pages/Measure/Measure')),
          //     },
          //     {
          //       key: 'taxClass',
          //       name: 'TaxClass',
          //       icon: 'Money',
          //       roles: [AdminRoles.ROOT_ADMIN,AdminRoles.ADMIN],
          //       component: React.lazy(() => import('./pages/TaxClass/TaxClass')),
          //     },
          //     {
          //       key: 'taxClassForm/:taxclassId',
          //       name: 'TaxClassForm',
          //       icon: 'Money',
          //       isHidden:true,
          //       roles: [AdminRoles.ROOT_ADMIN,AdminRoles.ADMIN],
          //       component: React.lazy(() => import('./pages/TaxClass/TaxClassForm')),
          //     },
          //   ]
          // }
        ]
      },
      {
        key: 'publish',
        name: 'Publish Management',
        children: [
          {
            key: 'manage',
            name: 'Published Objects',
            icon: 'PublishContent',
            component: React.lazy(() => import('./pages/PublishManagement')),
          },
          {
            key: 'publishedObjects',
            name: 'PublishedObjects',
            icon: 'PublisherLogoFill',
            isHidden: true,
            roles: [AdminRoles.ROOT_ADMIN, AdminRoles.ADMIN, UserRoles.PUBLISH_OBJECTS_READ, UserRoles.PUBLISH_OBJECTS_DELETE, UserRoles.PUBLISH_OBJECTS_UNPUBLISH],
            component: React.lazy(() => import('./pages/Publish/PublishedObjects')),
          },
          {
            key: 'publishedvariantattributes',
            name: 'PublishedVariantAttributes',
            icon: 'PublisherLogoFill',
            isHidden: true,
            roles: [AdminRoles.ROOT_ADMIN, AdminRoles.ADMIN, UserRoles.PUBLISH_OBJECTS_READ, UserRoles.PUBLISH_OBJECTS_DELETE, UserRoles.PUBLISH_OBJECTS_UNPUBLISH],
            component: React.lazy(() => import('./pages/Publish/PublishedVariantAttributes')),
          },
          {
            key: 'publishedPricingObjects',
            name: 'PublishedPricingObjects',
            icon: 'PublisherLogoFill',
            isHidden: true,
            roles: [AdminRoles.ROOT_ADMIN, AdminRoles.ADMIN, UserRoles.PUBLISH_OBJECTS_READ, UserRoles.PUBLISH_OBJECTS_DELETE, UserRoles.PUBLISH_OBJECTS_UNPUBLISH],
            component: React.lazy(() => import('./pages/Publish/PublishedPricingObjects')),
          },
          {
            key: 'importObjects',
            name: 'ImportObjects',
            icon: 'PublisherLogoFill',
            isHidden: true,
            roles: [AdminRoles.ROOT_ADMIN, AdminRoles.ADMIN, UserRoles.PUBLISH_OBJECTS_READ, UserRoles.PUBLISH_OBJECTS_DELETE, UserRoles.PUBLISH_OBJECTS_UNPUBLISH],
            component: React.lazy(() => import('./pages/Publish/ImportObjects')),
          },
          {
            key: 'publishedExternalObjects',
            name: 'publishedExternalObjects',
            icon: 'PublisherLogoFill',
            isHidden: true,
            roles: [AdminRoles.ROOT_ADMIN, AdminRoles.ADMIN, UserRoles.PUBLISH_OBJECTS_READ, UserRoles.PUBLISH_OBJECTS_DELETE, UserRoles.PUBLISH_OBJECTS_UNPUBLISH],
            component: React.lazy(() => import('./pages/Publish/PublishedExternalObjects')),
          },
          {
            key: 'syncedObjects',
            name: 'SyncedObjects',
            icon: 'PublisherLogoFill',
            isHidden: true,
            roles: [AdminRoles.ROOT_ADMIN, AdminRoles.ADMIN, UserRoles.PUBLISH_OBJECTS_READ, UserRoles.PUBLISH_OBJECTS_DELETE, UserRoles.PUBLISH_OBJECTS_UNPUBLISH],
            component: React.lazy(() => import('./pages/Publish/SyncedObjects')),
          },
          {
            key: 'publishedExchangeObjects',
            name: 'PublishedExchangeObjects',
            icon: 'PublisherLogoFill',
            isHidden: true,
            roles: [AdminRoles.ROOT_ADMIN, AdminRoles.ADMIN, UserRoles.PUBLISH_OBJECTS_READ, UserRoles.PUBLISH_OBJECTS_DELETE, UserRoles.PUBLISH_OBJECTS_UNPUBLISH],
            component: React.lazy(() => import('./pages/Publish/PublishedExchangeObjects')),
          },
          {
            key: 'publishedPrimitives',
            name: 'PublishedPrimitives',
            icon: 'PublisherLogoFill',
            isHidden: true,
            roles: [AdminRoles.ROOT_ADMIN, AdminRoles.ADMIN, UserRoles.PUBLISH_OBJECTS_READ, UserRoles.PUBLISH_OBJECTS_DELETE, UserRoles.PUBLISH_OBJECTS_UNPUBLISH],
            component: React.lazy(() => import('./pages/Publish/PublishPrimitive')),
          },
        ]
      },

      {
        key: 'management',
        name: 'System Management',
        icon: 'DataManagementSettings',
        children: [
          {
            key: 'mgmt',
            name: 'System Management',
            icon: 'DataManagementSettings',
            roles: [AdminRoles.ROOT_ADMIN, AdminRoles.ADMIN],
            component: React.lazy(() => import('./pages/SystemManagement')),
          },
          /*{
            key: 'organization',
            name: 'Organization',
            icon: 'Org',
            component: React.lazy(() => import('./pages/Organisation')),
          },*/
          {
            key: 'channel',
            name: 'Channel',
            icon: 'MapLayers',
            isHidden: true,
            roles: [AdminRoles.ROOT_ADMIN, AdminRoles.ADMIN],
            component: React.lazy(() => import('./pages/Channel/Channel')),
          },
          {
            key: 'channelShapeForm/:channelID',
            name: 'ChannelShapeForm',
            icon: 'MapLayers',
            isHidden: true,
            roles: [AdminRoles.ROOT_ADMIN, AdminRoles.ADMIN],
            component: React.lazy(() => import('./pages/Channel/Shape/ChannelShapeForm')),
          },
          {
            key: 'settings',
            name: 'Settings',
            icon: 'Settings',
            isHidden: true,
            children: [
              {
                key: 'settings',
                name: 'Settings',
                roles: [AdminRoles.ROOT_ADMIN, AdminRoles.ADMIN],
                component: React.lazy(() => import('./pages/Settings/Settings')),
              },
              {
                key: 'country',
                name: 'Country',
                roles: [AdminRoles.ROOT_ADMIN, AdminRoles.ADMIN],
                component: React.lazy(() => import('./pages/Country')),
              },
              {
                key: 'locale',
                name: 'Locale',
                roles: [AdminRoles.ROOT_ADMIN, AdminRoles.ADMIN],
                component: React.lazy(() => import('./pages/Locale')),
              },
              {
                key: 'currency',
                name: 'Currency',
                roles: [AdminRoles.ROOT_ADMIN, AdminRoles.ADMIN],
                component: React.lazy(() => import('./pages/Currency')),
              },
              {
                key: 'user',
                name: 'User',
                icon: 'People',
                roles: [AdminRoles.ROOT_ADMIN, AdminRoles.ADMIN],
                component: React.lazy(() => import('./pages/User/User')),
              },
              {
                key: 'userRole',
                name: 'Roles',
                icon: 'SecurityGroup',
                roles: [AdminRoles.ROOT_ADMIN, AdminRoles.ADMIN],
                component: React.lazy(() => import('./pages/User/UserRole')),
              },
              {
                key: 'measure',
                name: 'Measure',
                icon: 'Weights',
                roles: [AdminRoles.ROOT_ADMIN, AdminRoles.ADMIN],
                component: React.lazy(() => import('./pages/Measure/Measure')),
              },
              {
                key: 'taxClass',
                name: 'TaxClass',
                icon: 'Money',
                roles: [AdminRoles.ROOT_ADMIN, AdminRoles.ADMIN],
                component: React.lazy(() => import('./pages/TaxClass/TaxClass')),
              },
              {
                key: 'taxClassForm/:taxclassId',
                name: 'TaxClassForm',
                icon: 'Money',
                isHidden: true,
                roles: [AdminRoles.ROOT_ADMIN, AdminRoles.ADMIN],
                component: React.lazy(() => import('./pages/TaxClass/TaxClassForm')),
              },
            ]
          }
        ]
      }
    ]
  },
  null,
  pathGenerator
);


export default routeConfig;
